.nav {
    height:  100px;
  text-align: center;
  background-color: #000;
  margin-top: -100px;
}

.inner {
    margin:  auto;
    height:  80px;
    width:  100%;
    max-width: 1170px;
    line-height: 80px;
}

.nav-logo-container {
    float:  left;
}

.mli {
    text-align: center;
    margin-right: 1px;
}

.nav-item {
    font-family: .AppleSystemUIFont;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
    letter-spacing: 0;
    padding-left: 25px;
    padding-right: 25px;
}

.nav-item a:active {
}

.menu {
    margin:  auto;
    text-align: center;
}

.nav{
  position: fixed;
  width: 100%;
  text-align: center;
}

nav li{
  display: inline;
}


.nav a{
  padding-bottom: 30px;
}

.nav a:hover{
}

.nav li.active a{
  border-bottom: 2px solid white;
}

.aactive {
  border-bottom: 2px solid white;
}